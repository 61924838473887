import React from "react";
import './styles.css'
import Logo from "../../assets/Logo2.png"
import { Link } from "react-router-dom";

function Footer(){
    return(
     <>
     <footer>
        <div className="footer">
        <Link style={{textDecoration:'none'}} to='/'>
                <img id='logo' src={Logo} alt="EA logo"></img>      
            </Link>
            
            <div className="footer-rights">
                <p id="text-footer">Todos os direitos reservados ©
                    <br/>
                    Desenvolvido por Anna Sanches
                </p>
            </div>

            <nav className="nav-footer">
                <ul className="footer-list">
                <Link style={{textDecoration:'none'}} to='/menuFesta'>
                         <li>Menu da festa</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/gravata'>
                        <li>Gravata</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/infoExtra'>
                        <li>Informações extras</li>
                    </Link>
                </ul>
            </nav>

        </div>
     </footer>
     
     </>   
    )
}

export default Footer;