import React from "react";
import './styles.css'
import Header from "../../components/headerFesta/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footerFesta/footer";

function MenuFesta(){
    return(
        <>
        <Header />
        <Banner />
        <div className="title-div">
            <span className="title">
                Menu<br/>
            </span>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Entradas</span>
            </div>
            <div className="MenuPart-body">
                <span>
                - Copinho de pinga com carpaccio ao molho de alcaparras e parmesão <br/>
                - Bolinho de carne de panela em dip de pimenta<br/>
                - Queijo coalho tostado com melaço de cana <br/>
                - Phylo com queijo brie e geleia de damasco <br/>
                - Camarão crocante servido com aioli <br/>
                - Polenta com ragu de costela <br/>
                - Escondidinho de carne seca<br/>
                - Mini cuscuz paulista
                </span>
            </div>
        </div>
        
        
        <Footer />
        </>
    )
}

export default MenuFesta;