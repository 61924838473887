import React from "react";
import './styles.css'
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import LogoThaisImg from '../../assets/LogoThaiKampai.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShoePrints} from '@fortawesome/free-solid-svg-icons'

function Presenca(){
    return(
        <>
        <Header />
        <Banner />
        <div className="espaco-presenca"></div>
        <div className="espaco-presenca"></div>
        <div className="title-page">
            <span className="conf-title">
                Confirmação de Presença
            </span>
        </div>
        <div className="espaco-presenca"></div>
        <div className="conf-instrução">
            <span className="conf-text">
                A equipe da nossa cerimonialista, Thais Kampai, entrará em contato pelo WhatsApp para confirmar a presença de vocês.
                <br/>
                Pedimos, por gentileza, que respondam à confirmação.
                <br/>
                Se tiverem dúvidas, estamos à disposição!
                
            </span>
            <div className="espaco-presenca"></div>
            <a href="https://www.instagram.com/thaiskampai?igsh=cm42MG0zNGNiNW91" target="_blank">
                <img id='logoThais-img' src={LogoThaisImg} alt="logo Thais Kampai img"></img>
            </a>
            <div className="espaco-presenca"></div>
            <div className="espaco-presenca"></div>
        </div>
        
        <div className="espaco-presenca"></div>
        <Footer />
        </>
    )
}

export default Presenca;