import React from "react";
import './styles.css'
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import NuBankImg from '../../assets/Nubank-Pix.jpg'
import ICaseiImg from '../../assets/iCasei2.png'
import Pix from '../../assets/qrcode-pix.png'

function Presentes(){
    return(
        <>
        <Header />
        <Banner />
        <div className="espaco-extra"></div>
        <div className="presentes-main">
            <div className="presente-title">
                <span>
                Aqui você vai encontrar as opções de presentes que disponibilizamos para o nosso casamento.
                <br/>
                </span>
            </div>
            <div className="espaco-presenca"></div>
            <div className="opcao">
                <span className="op-title">
                    Opção 1: Pix<br/>
                </span>
                <span className="op-text">
                    O valor vai para uma conta do Nu Bank que está no nome do Eduardo H. Bertin.
                    <br/><br/>
                </span>
                <div className="pix-info">
                    <div className="pix-info-text">
                        <span className="op-text">A chave pix é o nosso e-mail: <br/></span>
                        <span id="pix-email">eduardo_anna@outlook.com</span>
                        <span id="pix-extra"><br/>* Igual está no convite que você recebeu.</span>
                    </div>
                    <div className="pix-qrcode-img">
                        <img id='qrcode-img' src={Pix} alt="Pix QRCode"></img>
                    </div>
                    <div className="pix-info-img">
                        <img id='nubank-img' src={NuBankImg} alt="Nu Bank e Pix img"></img>
                    </div>
                </div>
                
            </div>
            <div className="espaco"></div>
            <div className="opcao">
                <span className="op-title">
                    Opção 2: Lista de presentes<br/>
                </span>
                <span className="op-text">
                    Do iCasei, que possui taxa já adicionada ao preço do presente.
                    <br/>
                    Acesso a lista por meio da logo iCasei a seguir:
                </span>
                <div className="icasei-img-main">
                    <div className="icasei-img-link">
                        <Link style={{textDecoration:'none'}} to='/presentes-icasei'>
                            <img id='icasei-img' src={ICaseiImg} alt="iCasei logo img"></img>
                        </Link>
                    </div>
                </div>
                
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Presentes;