import React from "react";
import './styles.css'
import Header from "../../components/headerFesta/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footerFesta/footer";
import Pix from '../../assets/qrcode-pix.png'
import NuBankImg from '../../assets/Nubank-Pix.jpg'

function Gravata(){
    return(
        <>
        <Header />
        <Banner />
        <div className="title-div">
            <span className="title">
                Gravata<br/>
            </span>
        </div>
               
        <div className="pix-info">
            <div className="pix-info-text">
                <span className="op-text">A chave pix é o nosso e-mail: <br/></span>
                <span id="pix-email">eduardo_anna@outlook.com</span> 
            </div>
            <div className="pix-qrcode-img">
                <img id='qrcode-img' src={Pix} alt="Pix QRCode"></img>
            </div>
            <div className="pix-info-img">
                <img id='nubank-img' src={NuBankImg} alt="Nu Bank e Pix img"></img>
            </div>
        </div>

        <div className="title-div">
            <span className="op-text">
                O valor vai para uma conta do Nu Bank que está no nome do Eduardo H. Bertin.
                <br/><br/>
            </span>
        </div>
        
        
        <Footer />
        </>
    )
}

export default Gravata;