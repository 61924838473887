import './App.css';
import Home from './pages/home/home';
import Igreja from './pages/igreja/igreja';
import Festa from './pages/festa/festa';
import Presenca from './pages/presenca/presenca';
import Presentes from './pages/presentes/presentes';
import Icasei from './pages/presentes/icasei/icasei';
import MenuFesta from './pages/menu/menuFesta';
import Informacoes from './pages/informacoes/info';
import Gravata from './pages/gravata/gravata';
import { HashRouter,Routes, Route } from 'react-router-dom';
import Mensagem from './pages/mensagens/mensagens';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <> 
    <HashRouter>
    <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/igreja' element={<Igreja />} />
        <Route path='/festa' element={<Festa />} />
        <Route path='/presenca' element={<Presenca />} />
        <Route path='/presentes' element={<Presentes />} />
        <Route path='/presentes-icasei' element={<Icasei />} />
        <Route path='/mensagens' element={<Mensagem />} />
        <Route path='/menuFesta' element={<MenuFesta />} />  
        <Route path='/infoExtra' element={<Informacoes />} /> 
        <Route path='/gravata' element={<Gravata />} /> 
      </Routes>
    </HashRouter>
    </>
  );
}

export default App;
