import React from "react";
import './styles.css'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleInfo, faUtensils} from '@fortawesome/free-solid-svg-icons'
import {faBlackTie}from '@fortawesome/free-brands-svg-icons'

function Header(){
    return(
        <>
        <header>
            <nav className="nav-header">
                <ul>
                    <Link style={{textDecoration:'none'}} to='/menuFesta'>
                         <li>Menu da festa</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/gravata'>
                        <li>Gravata</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/infoExtra'>
                        <li>Informações extras</li>
                    </Link>
                </ul>
            </nav>

            <nav className="nav-header-mobile">
                <div className="espaco"></div>
                <div className="icons-header-main">
                    <Link style={{textDecoration:'none'}} to='/menuFesta'>
                        <FontAwesomeIcon icon={faUtensils} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/gravata'>
                        <FontAwesomeIcon icon={faBlackTie} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/infoExtra'>
                        <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                    </Link>
                </div>
                <div className="espaco"></div>
            </nav>
        </header>
        </>
    )
}

export default Header;