import React from "react";
import './styles.css'
import Header from "../../components/headerFesta/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footerFesta/footer";

function Informacoes(){
    return(
        <>
        <Header />
        <Banner />
        <div className="title-div">
            <span className="title-I">
                Informações extras<br/>
            </span>
        </div>
        
        
        <Footer />
        </>
    )
}

export default Informacoes;